@mixin heading-h1 {
    font-weight: 600;
    font-size: 48px;
}

@mixin heading-h2 {
    font-weight: 600;
    font-size: 32px;
}

@mixin heading-h3 {
    font-weight: 600;
    font-size: 24px;
}

@mixin heading-h4 {
    font-weight: 600;
    font-size: 16px;
}

@mixin font-light {
    font-family: Roboto-Regular, sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
}

@mixin font-regular {
    font-family: Roboto-Regular, sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}

@mixin font-semi-bold {
    font-family: Roboto-Regular, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}

@mixin general-font-size {
    font-size: 16px;

    @include at-least-tablet {
        font-size: 18px;
    }

}
