.specialists {
  .card {
    padding: 8px;
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      margin-bottom: 4px;
    }

    p {
      font-size: 15px;
      margin-bottom: 0px;
    }

    div {
      max-width: 100%;
      height: 200px;
      width: auto;
      background-size: cover;
      margin-bottom: 8px;
    }
  }
}