.breadcrumbs {
  list-style:    none;
  padding:       0;
  font-size:     14px;
  @include font-semi-bold;
  margin-bottom: 0;
  height:        0 !important; /* to hide on mobile, but keep the existing padding */
  opacity:       0 !important;
  @include at-least-tablet {
    height:        auto !important;
    opacity:       1 !important;
    margin-bottom: 20px;
  }

  > li {

    display: inline;
    padding: 0;
    margin:  0;

    > i {
      width:        10px;
      height:       10px;
      fill:         transparent;
      stroke:       $color-primary;
      stroke-width: 10px;
      margin:       0 5px;
    }

    a {
      color: $color-grey;
    }

    &:first-child {
      a {
        padding-left: 0;
      }
    }

    &:last-child {
      a {
        padding-left: 0;
      }
    }
  }
}