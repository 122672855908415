.categories, .references {

  &.finished {
    background: $color-white;
  }

  &.finished,
  &.in_progress {
    text-align: center;

    h2 {
      margin-top: 16px;
      margin-bottom: 48px;
    }

    .row {
      justify-content: center;
    }
  }

  .card {
    margin-bottom: 16px;
  }

  .category, .reference {
    margin: 16px;
    text-align: center;
    color: $color-primary;

    &__image {
      width: 100%;
      height: 200px;
      margin-bottom: 16px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .search {
    padding: 16px;
    margin-bottom: 32px;
    background: $color-light-grey;

    form {
      width: 100%;

      input {
        width: 100%;
        margin-bottom: 8px;
        min-height: 40px;
        border: 1px solid $color-light-grey;
        border-radius: 8px;
        outline: none;
        padding: 8px;

        &:focus {
          border: 1px solid $color-grey;
          outline: none;
        }
      }

      button {
        width: 100%;
        height: 40px;
        background: $color-primary;
        color: $color-white;
        border-radius: 8px;
        justify-self: end;

        &:hover {
          background: $color-primary-dark;
        }
      }

      @include at-least-tablet() {
        input {
          width: 76%;
          margin-bottom: 0px;
        }

        button {
          width: 23%;
        }
      }
    }
  }
}

.reference {

  .gallery {
    //height: 400px;

    .slick-arrow {
      display: none !important;
    }

    img {
      //max-width: 700px;
      height: 400px;
    }
  }

  .richtext {
    margin-top: 24px;
  }
}