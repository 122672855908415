*, *:before, *:after {
    box-sizing: border-box;
}

body {
    margin:      0;
    position:    relative;
    z-index:     $z-body;
    color:       $color-text;
    line-height: 1.6;

    &.fullHeight {
        height: 100vh;

        main {
            @include at-least-tablet {
                height: calc(100% - 120px);
            }

            @include at-least-desktop {
                height: calc(100% - 96px);
            }

            > section {
                height: 100%;
            }
        }
    }

    @include general-font-size;

    @include font-light;

    @include at-least-tablet {
        overflow:   auto;
        overflow-x: hidden;
        z-index:    $z-body-tablet;
    }

    @include at-least-desktop {
        z-index: $z-body-desktop;
    }
}

main {
    .fullHeight & {
        height: calc(100% - 200px);

        .wrap {
            height: 100%;
        }
    }
}

img {
    display:   block;
    max-width: 100%;
    height:    auto;
}

.container {
    @include container(960px + $grid-gutter-full*2);
    @include grid-container();
}

.wrap {
    padding:    24px 0;
    overflow-x: hidden;

    @include at-least-tablet {
        padding: 40px 0 48px 0;
    }

    &--clear-top {
        padding-top: 0;
    }

    &--inner {
        padding: 24px 0;
    }

    &--large {
        padding: 32px 0;

        @include at-least-tablet {
            padding: 64px 0;
        }
    }

    @include block-heading;
}

.grid-row {
    &--tablet {

        @include grid-media($neat-grid-tablet) {
            @include grid-gutter-fix;
            display:   flex;
            flex-wrap: wrap;
        }
    }

    &--desktop {
        @include grid-media($neat-grid-desktop) {
            @include grid-gutter-fix;
            display:   flex;
            flex-wrap: wrap;
        }
    }
}

.heading {
    @include heading-h2;
    padding-bottom: $grid-gutter;

    @include at-least-tablet {
        padding-bottom: $grid-gutter-full;
    }

    a {
        color: inherit;
    }
}

.section-title {
    font-size:      18px;
    padding-bottom: $grid-gutter;
    line-height:    1;

    @include at-least-tablet {
        padding-bottom: $grid-gutter-full;
    }

    a {
        color: inherit;
    }
}

.top-title {
    margin-bottom: $grid-gutter;
    line-height: 1;
}

.hidden-mobile {
    display: none;
    @include at-least-tablet {
        display: block;
    }
}

.js-hidden {
    display: none;
}