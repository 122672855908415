
.banner {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: table;
  color: $color-white;
  text-shadow: 2px 2px 2px #000;

  h2 {
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    font-size: 24px;
  }

  h3 {
    text-align: center;
    font-size: 24px;
  }

  &__info {
    display: table-cell;
    vertical-align: middle;
    padding: auto 16px;
  }

  @include at-least-tablet {
    height: 300px;

    h2 {
      font-size: 32px;
    }

    h3 {
    }
  }

  @include at-least-desktop {
    height: 400px;

    h2 {
      @include heading-h2;
    }

    h3 {
      @include heading-h3
    }
  }
}