@mixin main-sub-menu(){
  $mixinBase: &;

  display: block;
  //background: $color-neutral-8;
  border-bottom: 1px solid $color-light-grey;

  @include at-least-tablet {
    text-align: center;
  }

  &__list {

    margin: 0;
    padding: 0;
    list-style: none;

    &__item {
      display: inline-block;
      margin: 0;
      padding: 0 $grid-gutter;

      &:before {
        display: none;
      }

      &.active {
        a {
          text-decoration: none;
          color: $color-primary;
          &:after {
            width: 100%;
          }
        }
      }

      a {
        display: block;
        padding: $grid-gutter 0;
        @include font-semi-bold;
        color: $color-grey;
        font-size: 16px;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: -1px;
          height: 1px;
          width: 0;
          background-color: $color-primary;
        }

        &:hover {
          text-decoration: none;
          color: $color-primary;
          &:after {
            width: 100%;
          }
        }

        i {
          line-height: 0.8;
          display: none;
        }
      }
    }
  }

  @media (max-width: $menu-bp - 1px) {
    max-height: 50px;
    overflow: hidden;
    transition: all $transition-timing $transition-animation;

    &--open {
      max-height: 700px;

      a i {
        transform: rotate(180deg);
      }
    }

    #{$mixinBase}__list {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &__item {
        width: 100%;
        padding: 5px 0 0 0;
        border-bottom: $color-light-grey solid 1px;
        text-align: left;
        order: 2;

        i {
          transition: all $transition-timing $transition-animation;
        }

        a {
          display: block;
          width: auto;

          &:after {
            width: 100%;
            left: -100%;
          }

          &:hover {
            &:after {
              left: 0;
            }
          }
        }

        &.active {
          order: 1;
          padding: 0;

          a {
            display: inline-block;
            color: $color-primary;
            line-height: 1.1;

            i {
              display: inline-block;
              margin-left: 5px;
              width: 12px;
              height: 12px;
              fill: transparent;
              stroke: $color-primary;
              stroke-width: 8px;
            }
          }
        }
      }
    }
  }
}

.submenu {
  @include main-sub-menu;
}
