//Page container settings
/* LAYOUT CONFIGURATION */

// Max width used for grid
$max-width: 960px;
// Max width used for container - basically it`s $max-width + $cont-padding * 2
$cont-max-width: 960px;
$cont-padding: 16px;
$cont-padding-tablet: 32px;
$menu-bp: 960px;
// You can set paddings for each breakpoint as well here...
// $cont-padding-tablet: rem(30) !global;
// $cont-padding-desktop: rem(40) !global;

/* BREAKPOINTS */

$mobile-large: 440px;
$tablet: 590px;
$tablet-850: 850px;
$desktop: 1024px;
$desktop-1126: 1126px;
$large-desktop: 1280px;
$desktop-1366: 1366px; // common resolution
$z-body: 1;
$z-body-tablet: 2;
$z-body-desktop: 3;

@mixin at-least-mobile-large {
  @media(min-width: $mobile-large) {
    @content;
  }
}

@mixin at-least-tablet {
  @media(min-width: $tablet) {
    @content;
  }
}

@mixin at-least-tablet-850 {
  @media(min-width: $tablet-850) {
    @content;
  }
}

@mixin at-least-desktop {
  @media(min-width: $desktop) {
    @content;
  }
}

@mixin at-least-large-desktop {
  @media(min-width: $large-desktop) {
    @content;
  }
}

@mixin at-least-desktop-1126 {
  @media(min-width: $desktop-1126) {
    @content;
  }
}

@mixin at-least-desktop-1366 {
  @media(min-width: $desktop-1366) {
    @content;
  }
}

/* GRID CONFIG */

// Default columns count
$grid-columns: 6;
// Here you can define column & gutter size
// 3 & 1 is proportions. It means column size is 3x greater than gutter
$column: modular-scale(3, 1em, $golden) !default;
$gutter: modular-scale(1, 1em, $golden) !default;

/* new grids  configuration */

$grid-gutter: 16px;
$grid-gutter-full: 32px;

$neat-grid: (
        columns: 12,
        gutter: $grid-gutter,
);

$neat-grid-tablet: (
        media: $tablet,
        columns: 12,
        gutter: $grid-gutter-full
);

$neat-grid-tablet-850: (
        media: $tablet-850,
        columns: 12,
        gutter: $grid-gutter-full
);

$neat-grid-desktop: (
        media: $desktop,
        columns: 12,
        gutter: $grid-gutter-full
);

$neat-grid-large-desktop: (
        media: $large-desktop,
        columns: 12,
        gutter: $grid-gutter-full
);

@mixin flex-grid-column($columns: null, $grid: $neat-grid) {
  $columns: _neat-column-default($grid, $columns);
  $_grid-columns: _retrieve-neat-setting($grid, columns);
  $_grid-gutter: _retrieve-neat-setting($grid, gutter);

  flex: 0 0 calc(#{_neat-column-width($grid, $columns)});
  margin-#{_neat-float-direction($grid)}: $_grid-gutter;
}
