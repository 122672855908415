.management {
  .card {
    padding: 16px;
    margin-bottom: 16px;

    .profile-info {
      display: flex;
      align-items: center;

      div {
        height: auto;
        margin: 0
      }
    }

    h3 {
      font-size: 18px;
      margin-bottom: 4px;
    }

    p {
      font-size: 15px;
      margin-bottom: 0px;
    }

    div {
      max-width: 100%;
      height: 200px;
      width: auto;
      background-size: cover;
      background-position: center;
      margin-bottom: 8px;
    }
  }
}