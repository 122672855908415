.values {
  text-align: center;
  padding: 64px 0;
  color: $color-text;

  h2 {
    margin-bottom: 60px;
  }

  .row {
    justify-content: center;
    text-align: center;
  }

  i {
    font-size: 40px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
  }

  .our-value {
    margin-bottom: 24px;
  }
}