.pagination {
  margin: 0 16px;
  i {
    font-size: 15px;
    line-height: 1.6;
  }

  .pagination__item {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    background: $color-white;
    margin: 4px;
    border-radius: 4px;
    font-weight: 500;

    a {
      color: $color-primary;
    }

    &.active {
      color: $color-white;
      background: $color-primary;
    }

    &.nav{
      background: none;
    }
  }
}