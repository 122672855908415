.articles {
  .article-item {
    margin-bottom: 16px;

    .card {
      padding: 20px;
    }

    .title {
      color: $color-text;
      margin-bottom: 8px;
    }

    .details {
      color: $color-grey;
      font-size: 14px;
    }
  }
}