.footer {
  background: #fff;
  padding: 35px 15px;
  line-height: 20px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  img {
    display: inline-block;
  }

  .footer-info {
    margin-bottom: 20px;
  }

  .company-name {
    font-weight: 700;
    font-size: 14px;
  }

  .company-address {
    font-size: 14px;
  }

  .footer-logo {
    text-align: right;
  }
}
