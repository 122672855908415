/* TRANSITIONS */
$transition-animation: cubic-bezier(0.35, 0, 0, 1);
$transition-timing: 0.3s;

// How to use?
// @include transition;                                -> sets transition to `all` with default timing
// @include transition(background)                     -> sets transition to `background` with default timing
// @include transition(background color)               -> sets transition to `background` & `color` with default timings
// @include transition((background, color))            -> sets transition to `background` & `color` with default timings
// @include transition((background: .15s, color: .2s)) -> sets transition to `background` & `color` with given timings
@mixin transition( $attributes: () ) {
    @if length( $attributes ) >= 1 {

        $value: "";

        @each $attr, $time in $attributes {

            @if $time {
                $value: #{$value} #{$attr} #{$time};
            } @else {
                $value: #{$value} #{$attr} #{$transition-timing}
            }

            @if index( $attributes, $attr ) != length( $attributes ) {
                $value: "#{$value}, ";
            }
        }

        transition: #{$value} $transition-animation;
    } @else {
        transition: all 0.3s $transition-animation;
    }

}

@mixin transition-linear() {
    transition: all 0.3s;
}