@mixin container( $max-width: $cont-max-width) {
    max-width: $max-width + (2 * $cont-padding);
    margin: {
        left: auto;
        right: auto;
    }
    width: 100%;
    padding-left: $cont-padding;
    padding-right: $cont-padding;
}

@mixin block-heading {
    .block-heading {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-bottom: 1px solid $color-light-grey;
        padding-bottom: 10px;
        margin-bottom: 17px;

        @include at-least-desktop {
            padding-bottom: 8px;
            margin-bottom: 32px;
        }

        h2 {
            margin: 0;
            padding: 0;
            border: none;

            @include at-least-desktop {
                font-size:   28px !important;
                line-height: 38px !important;
            }
        }
    }
}

@mixin grid-gutter-fix($gutter: $grid-gutter-full){
    margin-left: - $gutter;
    margin-right: - $gutter;
}
