.header {

  position: relative;
  padding:  0;
  @include box-shadow;
  z-index:  999;

  @media (min-width: $menu-bp) {
    max-height: 96px + 32px;
    height:     100%;

    .container {
      position: static;
    }
  }

  &__logo {

    font-size:   0;
    line-height: 0;
    max-width: 139px;
    display: inline-block;

    .icon {
      height: 32px;
      width:  92px;

      @media (min-width: $menu-bp) {
        height: 48px;
        width:  auto;
      }
    }
  }

  &__upper_inner {
    @media (min-width: $menu-bp) {
      order:   2;
      padding: 0 30px 0 30px;
    }
  }

  &__upper {
    height: 26px;
    background: $color-primary-dark;
    color: $color-light-grey;
    text-align: right;
    font-size: 14px;

    a {
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  &__inner {
    padding:         16px 0 0 0;
    display:         flex;
    justify-content: space-between;
    flex-wrap:       wrap;
    align-items:     center;

    &.sticky {
      position:         fixed;
      top:              0;
      left:             0;
      right:            0;
      background-color: white;
      padding:          16px 18px 0;
      @include box-shadow;

      @media (min-width: $menu-bp) {
        top: 0;
      }
    }

    @media (min-width: $menu-bp) {
      padding:   23px 0;
      .has-logged-in & {
        padding: 17px 0;
      }
      flex-wrap: nowrap;
    }
  }

  &__navigation {
    flex-grow: 1;
    overflow:  hidden;

    .js & {
      opacity: 0;

      &.css-loaded {
        opacity: 1;
      }
    }

    @media (min-width: $menu-bp) {
      order:   2;
      padding: 0 30px 0 30px;
    }

    @include at-least-large-desktop {
      padding-left: 60px;
    }

    &:before {
      content:          '';
      display:          block;
      height:           1px;
      //width:            0px;
      //background-color: $color-neutral-7;
      margin:           16px -14px 0 -18px;

      @media (min-width: $menu-bp) {
        display: none;
      }
    }

    &.sticky {
      position:         fixed;
      top:              -21px;
      left:             0;
      right:            0;
      background-color: white;
      padding:          5px 10px;
      @include box-shadow;

      @media (min-width: $menu-bp) {
        top: 0;
      }

    }
  }
}

.menuWrap {
  position: relative;

  @media (min-width: $menu-bp) {
    overflow: hidden;
  }
}

.menu {

  display:        flex;
  flex-direction: row;
  flex-wrap:      wrap;

  @media(min-width: $menu-bp) {
    flex-wrap: wrap-reverse;
  }

  &__text-open {
    display: inline-block;

    .menu__toggle--open & {
      display: none;
    }
  }

  &__text-close {
    display: none;

    .menu__toggle--open & {
      display: inline-block;
    }
  }

  &__item {
    display: inline-block;
    margin:  0;

    &.active {
      a {
        color: $color-red;
      }
    }

    a {
      display:    block;
      color:      $color-grey;
      padding:    15px 10px 7px 10px;
      font-size:  18px;
      @include font-semi-bold;
      transition: color $transition-timing $transition-animation;

      &:hover {
        color: $color-red;
      }
      @media (min-width: $menu-bp) {
        padding:   7px 10px;
        font-size: $main-font-size;
      }
    }

    &.menu__toggle {
      padding: 10px 10px 11px;
    }

    .menu--secondary & {
      display:       block;
      padding-right: 20px;
      flex:          0 0 50%;

      @media (min-width: $menu-bp) {
        flex:          0 0 auto;
        padding-right: 0;
      }

      a {
        width:   auto;
        display: inline-block;
      }
    }

    .menu--main & {
      opacity: 0;

      &:first-child {
        opacity: 1;

        a {
          padding-left: 0;
        }
      }

      @media (min-width: 450px) and (max-width: 599px) {
        &:nth-child(2) {
          opacity: 1;
        }
      }

      @media (min-width: 600px) and (max-width: 749px) {
        &:nth-child(2), &:nth-child(3) {
          opacity: 1;
        }
      }

      @media (min-width: 750px) and (max-width: #{$menu-bp - 1}) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          opacity: 1;
        }
      }

      @media (min-width: $menu-bp) {
        &:nth-child(2), &:nth-child(3) {
          opacity: 1;
        }
      }

      @include at-least-desktop {
        opacity: 1;
      }

      .js-menu-loaded & {
        opacity: 1;
      }

      a {
        padding: 10px 10px 11px 10px;
      }
    }

  }

  &__toggle {
    cursor:           pointer;
    display:          inline-block;
    background-color: white;
    @include font-semi-bold;
    font-size:        18px;
    color:            $color-grey;
    transition:       color $transition-timing $transition-animation;

    &:hover {
      color: $color-red;
    }

    > i {
      display:        inline-block;
      vertical-align: middle;
      color:          $color-primary;
      width:          14px;
      height:         14px;
      stroke-width:   8px;
      margin-top:     -5px;
      stroke:         $color-primary;
      fill:           transparent;
      transition:     transform $transition-timing $transition-animation;
      transform:      rotate(0deg);
    }

    .hidden {
      display: none;
    }

    &.open {
      > i {
        transform: rotate(180deg);
      }
    }
  }

  &--secondary {
    max-height: 0;
    overflow:   hidden;
    transition: max-height $transition-timing $transition-animation, padding $transition-timing $transition-animation;

    @media(min-width: $menu-bp) {
      justify-content: center;
    }

    &.open {
      max-height: 600px;

      @media (min-width: $menu-bp) {
        padding: 10px 16px;

        .ie & {
          border-top: 1px solid $color-light-grey;
        }
      }
    }

    &:before {
      content:     '';
      display:     block;
      width:       100vw;
      height:      0;
      border-top:  $color-light-grey solid 1px;
      margin-left: -32px;

      @media (min-width: $menu-bp) {
        display: none;
      }
    }

    @media (min-width: $menu-bp) {
      position:   absolute;
      margin-top: 22px;
      left:       0;
      right:      0;
      background: white;
      @include box-shadow;
      border-top: 1px solid $color-light-grey;

      .ie & {
        border-top: none;
      }

      .ie & {
        border-top: 1px solid $color-light-grey;
      }

      .sticky & {
        margin-top: 0;
      }
    }
  }

  @media (min-width: $menu-bp) {
    text-align: center;
  }

  &--main {
    min-height:  50px;
    text-align:  left;
    white-space: nowrap;

    @media (min-width: $menu-bp) {
      max-height:      50px;
      overflow:        hidden;
      white-space:     normal;
      justify-content: flex-end;
    }

  }

}