.text-page {
  .card {
    padding: 16px;
  }

  .form-field {
    display: block;
    margin-bottom: 16px;

    &__label{
      display: block;
    }
  }

  input,
  textarea {
    min-height: 40px;
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    outline: none;
    padding: 8px;

    &:focus {
      border: 1px solid $color-grey;
      outline: none;
    }
  }

  button {
    width: 200px;
    background: $color-primary;
    margin-top: 16px;
    color: $color-white;
    padding: 16px;
    border-radius: 8px;

    &:hover {
      background: $color-primary-dark;
    }
  }
}